@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background: linear-gradient(
    92.23deg,
    #3651FF 21.43%,
    #439FF0 50.63%,
    #30EFF6 100%,
    #0EFFC8 117.04%
  );
  box-shadow: 0px 4.42184px 107.23px #30EFF6;
}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #0EFFC8 21.43%,
    #30EFF6 50.63%,
    #439FF0 100%,
    #3651FF 117.04%
  );
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #3651FF 21.43%,
    #439FF0 50.63%,
    #30EFF6 100%,
    #0EFFC8 117.04%
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

.profileImg {
  scale: 2.6;
}

.bg-about {
  scale: 1.5;
}
@media (max-width: 959px) {
  .bg-about {
    display: flex;
    scale: 1.15;
    margin-bottom: 0%;
    margin-top: 0%;
    padding-bottom: 0%;
  }

  .text-about {
    padding-top: 0%;
    margin-top: 0%;
  }

  .img-responsive {
    width: 114%;
    right: 47px;
    margin-bottom: 2.5%;
  }

  .gif-responsive {
    margin-top: 10%;
  }

  #services{
    margin-bottom: 22%;
  }


  #contact {
    margin-top: 32%;
  }
}

.contact-text-one {
  padding-bottom: 4%;
  line-height: 110%;
}
@media (max-width: 959px) {
  .contact-text-one {
    font-size: 35px;
    padding-bottom: 4%;
  }
}

  .contact-text-two {
  line-height: 170%;
  }
@media (max-width: 959px) {
  .contact-text-two {
    font-size: 26px;
  }
}

@media (max-width: 600px) {
  .img-responsive {
    width: 114%;
    right: 21px;
  }

  .bg-about {
    display: flex;
    scale: 2;
    margin-bottom: 0%;
    margin-top: 0%;
    padding-bottom: 0%;
  }

}

.github {
  scale: 2.4;
  color: #0EFFC8;
  float: right;
  padding-right: 2%;
  bottom: 30%;
}

.github:hover {
  color: #3651FF;
}

.scale-contact {
  scale: 1.4;
}

.footer {
  text-align: center;
  color: white;
  scale: 85%;
}

.bg-nav {
  background-color: rgba(54, 81, 255, 0.15);
}

#work {
  padding: 7%;
  margin-top: 10%;
  scale: 0.99;
}

#home {
  padding: 2% 5% 5% 5%;
  scale: 0.95;
}

#about {
  padding: 5%;
  margin-bottom: 5%;
  scale: 0.95;
}

#services {
  padding: 7%;
  margin-top: 10%;
  scale: 0.95;
}

#contact {
  padding: 5%;
  scale: 0.95;
}

.img-margins {
  margin-top: 7.5%;
  margin-right: 0%;
}

.text-margins {
  margin-left: 5%;
}

.img-scale {
  scale: 0.92;
  margin-top: 11.8%;
}

.gif-scale {
  scale: 1.05 1;
}

.margin-service-text {
  margin-bottom: 10%;
}

.copyright-text-color {
  color: #30EFF6;
}

.logo-align {
  position: relative;
  left: 130%;
  margin-bottom: 0;
  margin-top: 0;
  scale: 1.8;
}

/* .mg-bottom-services {
  margin-bottom: 0px;
} */
@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#3651FF] to-[#0EFFC8] 
    font-bold font-primary hover:from-[#0EFFC8] hover:to-[#3651FF];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
